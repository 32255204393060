<template>
  <div class="ma-0 pa-0">
    <DetailView
      v-if="dataLoaded && playlist" 
      :title="playlist.name"
      :imageSrc="playlist.imageSrc"
      @play="playOrLoad"
      @pause="pause"> 
      <PlaylistHeader @play="playAll"/>
      <PlaylistTracks :isOwner="isPlaylistOwner" :songs="playlist.songs" @playpause="playpause" />

      <template v-if="playlist.recommendedSongs && isPlaylistOwner">
        <v-card class="mt-12">
          <h5>{{ $t('playlist.recommended.label') }}</h5>
          {{ $t('playlist.recommended.text') }}
          <RecommendedTracks class="mt-6" :playlist="playlist" :songs="playlist.recommendedSongs" @allAdded="emptyList=true"/>
          <v-card-text v-if="emptyList">
            {{ $t('playlist.recommended.all-added') }}
          </v-card-text>
        </v-card>
      </template>
    </DetailView>
    <DetailLoader v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PlaylistHeader from '@/components/PlaylistHeader.vue'
import PlaylistTracks from '@/components/PlaylistTracks'
import RecommendedTracks from '@/components/RecommendedTracks'
import DetailView from '@/components/DetailView'
import DetailLoader from '@/components/DetailLoader'
import LfindIndex from 'lodash/findIndex'
import Lget from 'lodash/get'
import Lpick from 'lodash/pick'

export default {
  metaInfo() {
    return {
      title: this.$t('playlist.label'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.playlist.name
      }, {
        property: 'og:description',
        content: Lget(this, 'playlist.description', null) ? this.playlist.description : this.$t('playlist.default-meta-description')
      }]
    }
  },

  data: () => ({
    dataLoaded: false,
    emptyList: false
  }),

  components: {
    PlaylistHeader,
    PlaylistTracks,
    DetailView,
    DetailLoader,
    RecommendedTracks
  },

  async created() {
    await this.getPlaylist(this.$route.params.id)
    this.dataLoaded = true
  },

  computed: {
    ...mapGetters('playlists', ['playlist']),
    ...mapGetters('player', ['isPlaying', 'currentSongId', 'hasQueue']),
    ...mapGetters('users', ['user']),

    isPlaylistOwner() {
      return Lget(this, 'playlist.userId', null) == Lget(this, 'user.id', null)
    },
  },

  methods: {
    ...mapActions('playlists', ['playPlaylist', 'getPlaylist']),
    ...mapActions('player', ['play', 'pause']),

    Lpick: Lpick,

    playOrLoad() {
      if (this.hasQueue) {
        this.play()
        return
      }
      this.playAll()
    },

    playpause(fromSong) {
      if (this.isPlaying && this.currentSongId === fromSong.id) {
        this.pause()
        return
      }
      if (this.currentSongId === fromSong.id) {
        this.play()
        return
      }
      this.playAll(fromSong)
    },

    playAll(fromSong) {
      const idx = LfindIndex(this.playlist.songs, ['id', Lget(fromSong, 'id', 0)])
      this.playPlaylist({playlist: this.playlist, startIdx: (idx < 0 ? 0 : idx)})
    }
  }

}
</script>

<style scoped>
</style>
