<template>
  <div class="ma-0 pa-0">
    <DetailView 
      v-if="dataLoaded && album" 
      :title="album.albumName"
      :imageSrc="album.imageSrc"
      @play="playOrLoad"
      @pause="pause">
      <AlbumHeader @play="playAll"/>
      <AlbumTracks :songs="album.songs" @playpause="playpause" />
      <AlbumFooter v-if="$vuetify.breakpoint.mobile"/>

      <template v-if="sortedArtistAlbums">
        <v-row dense no-gutters class="mt-12">
          <h5>{{ $t('album.more-by-artist', {name: Lget(album, 'artist.artistName', 'Artist')}) }}</h5>
          <v-spacer></v-spacer>
          <v-btn text v-if="numArtistAlbums>6" @click="seeAllArtistAlbums=!seeAllArtistAlbums" data-test-id="showArtistAlbumsBtn">
            <template v-if="seeAllArtistAlbums">
              {{ $t('buttons.see-less') }}
            </template>
            <template v-else>
              {{ $t('buttons.see-all') }}
            </template>
          </v-btn>
        </v-row>
        <v-row dense>
          <v-col v-for="(row,i) in sortedArtistAlbums" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 'auto'">
            <AlbumItem :item="row" showYearType/>
          </v-col>
        </v-row>
      </template>

    </DetailView>
    <DetailLoader v-else />
  </div>
</template>

<script>
import AlbumHeader from '@/components/AlbumHeader'
import AlbumTracks from '@/components/AlbumTracks'
import AlbumFooter from '@/components/AlbumFooter'
import AlbumItem from '@/components/AlbumItem'
import DetailView from '@/components/DetailView'
import DetailLoader from '@/components/DetailLoader'
import { mapActions, mapGetters } from 'vuex'
import LfindIndex from 'lodash/findIndex'
import Lget from 'lodash/get'
import LsortBy from 'lodash/sortBy'

export default {
  metaInfo() {
    return {
      title: this.$t('album.label'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: Lget(this, 'album.albumName', 'Album')
      }, {
        property: 'og:description',
        content: this.$t('album.default-meta-description', {
          albumName: Lget(this, 'album.albumName', 'Album'),
          artistName: Lget(this, 'album.artist.artistName', 'Artist')
        })
      }]
    }
  },

  data: () => ({
    dataLoaded: false,
    seeAllArtistAlbums: false
  }),

  components: {
    AlbumHeader,
    AlbumTracks,
    AlbumFooter,
    DetailView,
    DetailLoader,
    AlbumItem
  },

  async created() {
    await this.getAlbum(this.$route.params.id)
    this.dataLoaded = true
  },

  computed: {
    ...mapGetters('albums', ['album']),
    ...mapGetters('player', ['isPlaying', 'currentSongId', 'hasQueue']),

    sortedArtistAlbums() {
      const sorted = LsortBy(Lget(this, 'album.artistAlbums.rows', []), ['year']).reverse()
      return this.seeAllArtistAlbums ? sorted : sorted.slice(0, 6)
    },

    numArtistAlbums() {
      return Lget(this, 'album.artistAlbums.count', 0)
    },

  },

  methods: {
    ...mapActions('albums', ['playAlbum', 'getAlbum']),
    ...mapActions('player', ['play', 'pause']),

    Lget: Lget,

    playOrLoad() {
      if (this.hasQueue) {
        this.play()
        return
      }
      this.playAll()
    },

    playpause(fromSong) {
      if (this.isPlaying && this.currentSongId === Lget(fromSong, 'id', 0)) {
        this.pause()
        return
      }
      if (this.currentSongId === Lget(fromSong, 'id', 0)) {
        this.play()
        return
      }
      this.playAll(fromSong)
    },

    playAll(fromSong) {
      const idx = LfindIndex(this.album.songs, ['id', Lget(fromSong, 'id', 0)])
      this.playAlbum({album: this.album, startIdx: (idx < 0 ? 0 : idx)})
    }
  }
}
</script>

<style scoped> 
</style>
