<template>
  <v-container class="ma-0 pa-0 transparent-background playlist-tracks">
    <SmallSongItems v-if="$vuetify.breakpoint.mobile" :songs="sortedSongs" :mode="isOwner ? 'playlist-owner' : 'playlist'" @playpause="$emit('playpause', arguments[0])"/>
    <TrackTable v-else :songs="sortedSongs" :mode="isOwner ? 'playlist-owner' : 'playlist'" @playpause="$emit('playpause', arguments[0])"/>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import LsortBy from 'lodash/sortBy'
import SmallSongItems from '@/components/SmallSongItems'
import TrackTable from '@/components/TrackTable'

export default {
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    isOwner: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SmallSongItems,
    TrackTable
  },

  computed: {
    sortedSongs() {
      return LsortBy(this.songs, ['PlaylistItem.sortOrder'])
    }
  }
}
</script>

<style scoped>
</style>