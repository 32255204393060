<template>
  <v-card-text>
    <div class="subtitle-1 mb-n2">{{ album.year }}</div>
    <div class="subtitle-1">{{ numTracks(album.songs) }} &bull; {{ playLength(album.songs) }}</div>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SongMixin } from '@/mixins'

export default {
  mixins: [
    SongMixin
  ],

  computed: {
    ...mapGetters('albums', ['album']),
  }
}
</script>

<style scoped>
</style>