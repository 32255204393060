<template>
  <v-container class="ma-0 pa-0 transparent-background">
    <SmallHeader v-if="$vuetify.breakpoint.mobile"
      :imageSrc="playlist.imageSrc"
      :title="playlist.name"
      :subtitle="`${Lget(playlist, 'owner.userName', '')} &bull; ${numTracks(playlist.songs)}, ${playLength(playlist.songs)}`"
      @play="$emit('play')"
    />

    <LargeHeader v-else
      :imageSrc="playlist.imageSrc"
      :title="playlist.name"
      :subtitle="`<strong>${Lget(playlist, 'owner.userName', '')}</strong> &bull; ${numTracks(playlist.songs)}, ${playLength(playlist.songs)}`"
      :type="$t('playlist.label')"
      :likeData="{id: playlist.id, type: 'Playlist', like: playlist.likes}"
      @play="$emit('play')"
    >
    <PlaylistMenu :playlist="playlist" class="ml-6"/>
    </LargeHeader>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import { SongMixin } from '@/mixins'
import SmallHeader from '@/components/SmallHeader'
import LargeHeader from '@/components/LargeHeader'
import PlaylistMenu from '@/components/PlaylistMenu'

export default {
  components: {
    SmallHeader,
    LargeHeader,
    PlaylistMenu
  },

  mixins: [
    SongMixin
  ],

  computed: {
    ...mapGetters('playlists', ['playlist']),
  },

  methods: {
    Lget: Lget
  }
}
</script>

<style scoped>
</style>