<template>
  <v-container class="ma-0 pa-0">
    <SmallHeader v-if="$vuetify.breakpoint.mobile"
      :imageSrc="album.imageSrc"
      :title="album.albumName"
      :subtitle="`${$t('album.albumBy')} ${Lget(album, 'artist.artistName', '')} &bull; ${album.year}`"
      @play="$emit('play')"
    />

    <LargeHeader v-else
      :imageSrc="album.imageSrc"
      :title="album.albumName"
      :subtitleLink="{
        label: Lget(album, 'artist.artistName', ''), 
        to: {name: 'Artist', params: {id: Lget(album, 'artist.id', -1) }}
      }"
      :subtitle="`&bull; ${album.year} &bull; ${numTracks(album.songs)}, ${playLength(album.songs)}`"
      :type="$t('album.label')"
      :likeData="{id: album.id, type: 'Album', like: album.likes}"
      @play="$emit('play')"
    >
    <AlbumMenu :album="album" class="ml-6"/>
    </LargeHeader>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import { SongMixin } from '@/mixins'
import SmallHeader from '@/components/SmallHeader'
import LargeHeader from '@/components/LargeHeader'
import AlbumMenu from '@/components/AlbumMenu'

export default {
  components: {
    SmallHeader,
    LargeHeader,
    AlbumMenu
  },

  mixins: [
    SongMixin
  ],

  computed: {
    ...mapGetters('albums', ['album']),
  },

  methods: {
    Lget: Lget,
  }
}
</script>

<style scoped>
</style>