<template>
  <v-dialog persistent v-model="showDialog" max-width="550px">
    <v-card>
      <v-form ref="form" :lazy-validation="true">
        <v-card-title>
          <h5>{{ $t('playlist.edit-details') }}</h5>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closed')">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card class="d-flex justify-start align-stretch transparent-background">
            <v-hover :value="hover">
              <template v-slot:default="{ hover }">
                <v-card flat class="transparent-background" height="200" width="200">
                  <v-layout justify-center wrap>
                    <v-img class="cover flex-grow-0 grey-background" min-height="200" min-width="200" contain height="200" width="200" :src="albumArt"/>
                    <v-fade-transition>
                      <v-overlay z-index="4" absolute opacity="0.8" v-if="hover">
                        <div>
                          <v-btn class="trans-btn" :ripple="false" fab x-large @click="$refs.uploadedCover.$refs.input.click()">
                            <v-icon size="100">{{ mdiPencilOutline }}</v-icon>
                          </v-btn>
                        </div>
                        <div class="mt-2">
                          {{ $t('playlist.choose-image') }}
                        </div>
                      </v-overlay>
                    </v-fade-transition>
                  </v-layout>
                </v-card>
              </template>
            </v-hover>           
            <div class="ml-2 flex-grow-1">
              <v-text-field
                outlined
                filled
                dense
                hide-details
                v-model="editData.name"
                class="mb-3"
                :label="$t('playlist.fields.name.label')"
                :placeholder="$t('playlist.fields.name.placeholder')"
                :rules="[rules.required]"
              ></v-text-field>
              <v-textarea
                outlined
                filled
                dense
                hide-details
                v-model="editData.description"
                :label="$t('playlist.fields.description.label')"
                :placeholder="$t('playlist.fields.description.placeholder')"
              ></v-textarea>
            </div>
          </v-card>
          <v-card height="32" class="d-flex justify-start align-stretch transparent-background mb-0 pb-0">
            <v-checkbox v-model="editData.isPrivate" :label="$t('playlist.fields.isPrivate.label')"></v-checkbox>
          </v-card>
        </v-card-text>
        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click="save">
            {{ $t('buttons.save') }}
          </v-btn>
        </v-card-actions>
        <v-card-text class="mt-1 pt-0 transparent-background">
          {{ $t('playlist.upload-file-message') }}
        </v-card-text>
        <v-file-input
          ref="uploadedCover"
          class="d-none"
          accept=".jpg"
          v-model="fileinput"
          @change="selectedFile"
        ></v-file-input>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiClose, mdiPencilOutline } from '@mdi/js'
import { RulesMixin } from '@/mixins'
import LisEmpty from 'lodash/isEmpty'
import Lget from 'lodash/get'

export default {

  props: {
    show: {
      type: Boolean,
      default: false
    },
    playlist: {
      type: Object
    }
  },

  mixins: [
    RulesMixin
  ],

  data: () => ({
    mdiClose,
    mdiPencilOutline,
    showDialog: false,
    editData: {},
    hover: false,
    fileinput: null,
  }),

  watch: {
    show (newV) {
      this.showDialog = this.show
      if (newV) {
        this.editData = {
          name: this.playlist.name,
          description: this.playlist.description,
          imageSrc: this.playlist.imageSrc,
          userUploadedImage: this.playlist.userUploadedImage,
          isPrivate: this.playlist.isPrivate
        }
      } else {
        this.editData = {
          name: null,
          description: null,
          imageSrc: '',
          userUploadedImage: false,
          isPrivate: false
        }
      }
    }
  },

  computed: {
    albumArt() {
      const cover = Lget(this, 'editData.imageSrc', null)
      const emptyCover = require("@/assets/images/album.png")
      return LisEmpty(cover) ? emptyCover : cover
    }
  },

  methods: {
    ...mapActions('playlists', ['updatePlaylist']),

    async save() {
      if (!this.$refs.form.validate()) {
        this.$events.fire('showError', this.$t('messages.form-validation-error'))
        return
      }

      // Remove large data if unchanged
      if (this.playlist.imageSrc === this.editData.imageSrc) {
        delete this.editData.imageSrc
      }
      if (this.playlist.description === this.editData.description) {
        delete this.editData.description
      }

      await this.updatePlaylist({ id: this.playlist.id, editData: this.editData })
      this.$emit('closed')
    },

    selectedFile(file) {
      const me = this
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        me.editData.imageSrc = reader.result
        me.editData.userUploadedImage = true
      };
      reader.onerror = function() {
        me.$events.fire('showError', this.$t('messages.file-parse-error'))
      };

    }
  }
}
</script>

<style scoped>
.trans-btn, .trans-btn:hover, .trans-btn:before, .trans-btn:after {
  background-color: transparent !important;
}
.grey-background {
  background-color: var(--v-background-base) !important;
}
</style>