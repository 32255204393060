<template>
  <v-container class="ma-0 pa-0 transparent-background">
    <SmallSongItems v-if="$vuetify.breakpoint.mobile" :songs="leftSongs" mode="recommended" @playpause="$emit('playpause', arguments[0])" @addTrack="addTrack"/>
    <TrackTable v-else :songs="leftSongs" mode="recommended" @playpause="$emit('playpause', arguments[0])" @addTrack="addTrack"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import LfindIndex from 'lodash/findIndex'
import SmallSongItems from '@/components/SmallSongItems'
import TrackTable from '@/components/TrackTable'

export default {
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    playlist: {
      type: Object,
      default: () => {}
    }
  },

  data: () =>({
    leftSongs: []
  }),

  created() {
    this.leftSongs = this.songs
  },

  watch: {
    songs: {
      handler: function () {
        this.leftSongs = this.songs
      },
      deep: true
    }
  },

  components: {
    SmallSongItems,
    TrackTable
  },

  computed: {
  },

  methods: {
    ...mapActions('playlists', ['addPlaylistTrack']),

    addTrack(track) {
      const idx = LfindIndex(this.leftSongs, ['id', track.id])
      if (idx > -1) {
        this.leftSongs.splice(idx,1)
        this.addPlaylistTrack({ id: Lget(this, 'playlist.id', 0), track })
      }
      if (this.leftSongs.length === 0) {
        this.$emit('allAdded')
      }
    }
  }
}
</script>

<style scoped>
</style>