<template>
  <v-container class="ma-0 pa-0 transparent-background album-tracks">
    <SmallSongItems v-if="$vuetify.breakpoint.mobile" :songs="sortedSongs" mode="album" @playpause="$emit('playpause', arguments[0])"/>
    <TrackTable v-else :songs="sortedSongs" mode="album" @playpause="$emit('playpause', arguments[0])"/>
  </v-container>
</template>

<script>
import LsortBy from 'lodash/sortBy'
import SmallSongItems from '@/components/SmallSongItems'
import TrackTable from '@/components/TrackTable'

export default {
  props: {
    songs: {
      type: Array,
      default: () => []
    }
  },

  components: {
    SmallSongItems,
    TrackTable
  },

  computed: {
    sortedSongs() {
      return LsortBy(this.songs, ['trackNum'])
    }
  }
}
</script>

<style scoped>
</style>